import React from 'react'
// import {Link} from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SideBar.css'
export default function SideBar() {
    return (
      <div className='sidebar'>

    </div>
    )
}