export default {
   
  //  API_ENDPOINT:'http://localhost:8000/api', 
  //  API_ENDPOINT:process.env.REACT_APP_API_ENDPOINT, 
 API_ENDPOINT:'https://boiling-caverns-54232.herokuapp.com/api',
    TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY,
    API_KEY: process.env.REACT_APP_API_KEY,
    APP_ID:process.env.REACT_APP_ID,
  }

